define('dynamicCreateEditList',[
    'module',
    'backbone',
    'marionette',
    'dynamicCreateEditListItem',
    'template!dynamicCreateEditList',
    'underscore'
], function (
    module,
    Backbone,
    Marionette,
    ItemView,
    Tpl,
    _
) {
    'use strict';

    module.exports = Marionette.CompositeView.extend({
        template: Tpl,
        childViewContainer: '.collection',
        childView: ItemView,
        childEvents: {
            'item:delete': function (view, model) {
                this.collection.remove(model);
                this.trigger('item:delete');
            }
        },
        events: {
            'click .mdi-plus-circle': 'onAdd'
        },
        className: 'list m-b-10',

        collectionEvents: {
            'change': 'render'
        },

        initialize: function () {
            this.filters = [];
            if (this.options.filterFromConfiguration) {
                _.each(this.options.filterFromConfiguration, _.bind(function (filter) {
                    this.model.on('change:' + filter.attributeChange, this.render);
                    this.filters.push(filter);
                }, this));
            }
        },
        onAdd: function () {
            var relation = this.model.getRelation(this.options.field);
            var reverseProperty = relation && relation.reverseRelation ? relation.reverseRelation.key : null;
            var data = {};
            if (reverseProperty) {
                data[reverseProperty] = this.model;
            }
            var model = this.model.get(this.options.field).model.prototype.getEntity().getModel(data);
            if (this.options.beforePush) {
                this.options.beforePush(model);
            }
            this.collection.push(model);
            model.set(data, {silent: true});
            if (this.options.afterPush) {
                this.options.afterPush(model);
            }
        },
        serializeData: function () {
            var templateData = {};
            var entity = this.model.get(this.options.field).model.prototype.getEntity();
            templateData.name = entity.getName();
            templateData.field = this.options.field;
            templateData.config = entity.getConfigCreateEditLine();
            templateData.fields = [];
            templateData.css = this.options.param && this.options.param.display && this.options.param.display.css ? this.options.param.display.css : '';
            _.each(entity.getCreateEditLine(), function (field) {
                if (field.param && field.param.display && field.param.display.headers) {
                    _.each(field.param.display.headers, function (header) {
                        templateData.fields.push(header);
                    });
                } else {
                    if (_.isArray(field.param)) {
                        var field2 = _.clone(field);
                        field2.param = field.defaultParam;
                        templateData.fields.push(field2);
                    } else {
                        templateData.fields.push(field);
                    }
                }
            });
            return templateData;
        },
        filter: function (child) {
            var display = true;
            _.each(this.filters, _.bind(function (filter) {
                var valueFromFilter = _.findWhere(filter.values, {value: this.model.get(filter.attributeChange)});
                if (valueFromFilter) {
                    if (_.isArray(valueFromFilter.filter.value)) {
                        display = display && valueFromFilter.filter.value.includes(child.get(valueFromFilter.filter.attribute));
                    } else {
                        display = display && valueFromFilter.filter.value === child.get(valueFromFilter.filter.attribute);
                    }
                }
            }, this));
            return display;
        }
    });
});

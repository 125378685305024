define('dynamicCreateEditListItem',['module',
        'marionette',
        'template!dynamicCreateEditListItem',
        'dialogFormView',
        'underscore',
        'jquery',
        'backbone',
        'dynamicListItemBoolean',
        'dynamicListItemColor',
        'dynamicListItemDate',
        'dynamicListItemDateTime',
        'dynamicListItemLabel',
        'dynamicListItemNumber',
        'dynamicListItemPopup',
        'dynamicListItemTextArea',
        'dynamicListItemRadio',
        'dynamicListItemText',
        'autocompleteView',
        'dynamicCreateEditCodeList',
        'dynamicCreateEditUserCodeList',
        'arrayInputView',
        'dynamicCreateEditSelect',
        'dynamicCreateEditCustomLisConfiguration',
        'dynamicCreateEditCustomAssayConfigurationResultFromAssay'],
    function (module,
              Marionette,
              ItemViewTpl,
              DialogFormView,
              _,
              $,
              Backbone,
              BooleanView,
              ColorView,
              DateView,
              DateTimeView,
              LabelView,
              NumberView,
              PopupView,
              TextAreaView,
              RadioView,
              TextView,
              AutocompleteView,
              CodeListView,
              UserCodeListView,
              ArrayListView,
              DynamicCreateEditSelect,
              DynamicCreateEditCustomLisConfiguration,
              DynamicCreateEditCustomAssayConfigurationResultFromAssay
    ) {
        'use strict';

        module.exports = DialogFormView.extend({
            template: ItemViewTpl,

            ui: {
                input: '.js-info-input-child',
                color: '.js-color',
                date: '.js-datePicker',
                regions: '.js-itemViews'
            },

            events: {
                'click .delete-row': 'onDelete',
                'focus @ui.input': 'onFocus',
                'change @ui.input': 'onInputChange',
                'change @ui.color': 'onChangeColor',
                'change @ui.date': 'onChangeDate',
                'click .js-popup': 'onPopupClick'
            },

            regions: {
                'itemViews': '.js-itemViews'
            },

            modelEvents: {
                'change': 'render',
                'sync': 'render'
            },

            initialize: function () {
                _.filter(this.model.getEntity().getCreateEditLine(this.model), _.bind(function (field) {
                    if (field.param.defaultValue) {
                        this.model.set(field.field, field.param.defaultValue(this.model));
                    }
                }, this));
            },

            serializeData: function () {
                var templateData = {};
                templateData.model = this.model.toJSON();
                var entity = this.model.getEntity();
                templateData.name = entity.getName();
                templateData.fields = _.clone(entity.getCreateEditLine(this.model));
                _.each(templateData.fields, _.bind(function (field) {
                    var param;
                    if (_.isArray(field.param)) {
                        param = _.first(_.filter(field.param, _.bind(function (param) {
                            return this.model.get(param.fieldNameKey) === param.fieldNameValue;
                        }, this)));
                    } else {
                        param = field.param;
                    }
                    if (!param) {
                        param = field.defaultParam;
                    }
                    field.param = param;
                    if (field.param.type === 'POPUP') {
                        field.param.display = field.param && field.param.display ? field.param.display : {};
                        field.param.display.css = (field.param.display.css ? field.param.display.css : '') + 'display: flex; justify-content: center;';
                    }
                    field.param.display.css = field.param && field.param.display && field.param.display.css ? field.param.display.css : '';
                    if (field.param.type === 'BOOLEAN') {
                        field.param.display.css += 'display: flex; align-items: center; justify-content: center;';
                    }
                    field.param.display['class'] = field.param && field.param.display && field.param.display['class'] ? field.param.display['class'] : '';
                    field.fieldName = field.field.replaceAll('.', '-');
                }, this));
                templateData.config = entity.getConfigCreateEditLine();


                return templateData;
            },

            getModel: function (model, fields) {
                if (fields.length === 1) {
                    return model;
                }
                var firstField = fields.shift();
                if (model instanceof Backbone.Model) {
                    model = model.attributes[firstField];
                } else if (_.isObject(model)) {
                    model = model[firstField];
                } else {
                    return model;
                }
                return this.getModel(model, fields);
            },
            _displayRegion: function (field2, clearValue, regionFromChange) {
                var field = _.findWhere(this.model.getEntity().getCreateEditLine(this.model), {'field': field2.field});
                var regionName = '.js-itemView-' + field.field.replaceAll('.', '-');
                var region;
                if (!regionFromChange) {
                    region = this.addRegion('itemView-' + field.field, regionName);
                } else {
                    region = regionFromChange;
                }


                var param;
                if (_.isArray(field.param)) {
                    param = _.first(_.filter(field.param, _.bind(function (param) {
                        return this.model.get(param.fieldNameKey) === param.fieldNameValue;
                    }, this)));
                } else {
                    param = field.param;
                }
                if (!param) {
                    param = field.defaultParam;
                }
                // clone l'objet pour ne pas modifier le param de base
                field = _.clone(field);
                field.param = param;

                if (field.dependsOn && !field.dependsOn.disableEvent) {
                    if (_.isArray(field.dependsOn.field)) {
                        _.each(field.dependsOn.field, _.bind(function (fieldDepends) {
                            this.listenTo(field.dependsOn.model, 'change:' + fieldDepends, _.bind(this._displayRegion, this, field2, field.dependsOn.clearValue !== undefined ? field.dependsOn.clearValue : true, region));
                        }, this));
                    } else {
                        this.listenTo(field.dependsOn.model, 'change:' + field.dependsOn.field, _.bind(this._displayRegion, this, field2, field.dependsOn.clearValue !== undefined ? field.dependsOn.clearValue : true, region));
                    }
                    if ((!this.model.get(field.dependsOn.field) && !field.dependsOn.allowEmpty) || (this.model.get(field.dependsOn.field) instanceof Backbone.Collection && this.model.get(field.dependsOn.field).isEmpty() && !field.dependsOn.allowEmpty)) {
                        region.show(new Marionette.ItemView({template: _.template('')}));
                        return;
                    }
                    if (field.dependsOn.enable) {
                        if (!field.dependsOn.enable(this.model)) {
                            region.show(new Marionette.ItemView({template: _.template('')}));
                            return;
                        }
                    }
                    if (field.dependsOn.readOnly) {
                        if (!field.param.display) {
                            field.param.display = {};
                        }
                        field.param.display.readOnly = field.dependsOn.readOnly(this.model);
                    }
                }

                if (field.param && field.param.display && field.param.display.condition) {
                    if (this.model.get(field.param.display.condition.key) !== field.param.display.condition.value) {
                        region.show(new Marionette.ItemView({template: _.template('')}));
                        return;
                    }
                }

                if (clearValue) {
                    var data = {};
                    data[field.field] = null;
                    this.model.set(data, {silent: true});
                }
                var model = this.model;
                if (field.field.includes('.')) {
                    var fields = field.field.split('.');
                    field.field = _.last(fields);
                    model = this.getModel(model, fields);
                }
                var view;

                switch (field.param.type) {
                    case 'TEXT':
                        view = new TextView({
                            model: model,
                            field: field
                        });
                        break;
                    case 'LABEL':
                        view = new LabelView({
                            model: model,
                            field: field
                        });
                        break;
                    case 'POPUP':
                        view = new PopupView({
                            model: model,
                            field: field
                        });
                        break;
                    case 'REFERENCE':
                        view = new AutocompleteView(
                            this._getAutocompleteOptionObject(field.param.config.getAutocompleteParam({
                                modelProperty: field.field,
                                callBackOnChange: field.param.self ? _.bind(this.onChangeCurrentModel, this) : _.bind(this.onChangeAutoComplete, this, field),
                                model: this.model,
                                readOnly: field.param.display ? field.param.display.readOnly : false
                            }, field.param.context, field.dependsOn)));
                        break;
                    case 'SELECT':
                        view = new DynamicCreateEditSelect({
                            model: model,
                            field: field.field,
                            values: field.param.config.values,
                            config: field.param.config
                        });
                        break;
                    case 'CODELIST':
                        view = new CodeListView({
                            model: model,
                            config: {
                                modelProperty: field.field,
                                code: field.param.code,
                                showAll: field.param.showAll
                            }
                        });
                        break;
                    case 'USERCODELIST':
                        view = new UserCodeListView({
                            model: model,
                            config: {
                                modelProperty: field.field,
                                code: field.param.code
                            }
                        });
                        break;
                    case 'DATE':
                        view = new DateView({
                            model: model,
                            field: field
                        });
                        break;
                    case 'DATETIME':
                        view = new DateTimeView({
                            model: model,
                            field: field
                        });
                        break;
                    case 'ARRAYLIST':
                        view = new ArrayListView({
                            model: model,
                            field: field
                        });
                        break;
                    case 'LISCONFIGURATION':
                        view = new DynamicCreateEditCustomLisConfiguration({
                            'field': field.field,
                            'model': this.model
                        });
                        break;
                    case 'COLOR':
                        view = new ColorView({
                            model: model,
                            field: field
                        });
                        break;
                    case 'NUMBER':
                        view = new NumberView({
                            model: model,
                            field: field
                        });
                        break;
                    case 'BOOLEAN':
                        view = new BooleanView({
                            model: model,
                            field: field
                        });
                        break;
                    case 'TEXTAREA':
                        view = new TextAreaView({
                            model: model,
                            field: field
                        });
                        break;
                    case 'RADIO':
                        view = new RadioView({
                            model: model,
                            field: field
                        });
                        break;
                    case 'ASSAYCONFIGURATIONRESULT_FROMASSAY':
                        view = new DynamicCreateEditCustomAssayConfigurationResultFromAssay({
                            'field': field.field,
                            'model': this.model
                        });
                        break;
                }
                if (!view) {
                    new Error('[DYNAMIC] no implementation for "' + field.param.type + '"');
                }
                if (field && field.param && field.param.onChange && field.param.type !== 'REFERENCE') {
                    // reference go to onAutocompleteChange
                    if (!view.onChange) {
                        this.onChange = _.bind(function (p1, p2) {
                            field.param.onChange(p1, p2, this.model);
                        }, this);
                    } else {
                        view.onChange = _.bind(function (p1, p2) {
                            field.param.onChange(p1, p2, this.model);
                        }, this);
                    }
                }
                if (!field.dependsOn || field.dependsOn.disableEvent) {
                    this.listenTo(this.model, 'change:' + field.field, _.bind(this._displayRegion, this, field2, false, region));
                }
                region.show(view);
            },
            onRender: function () {
                _.filter(this.model.getEntity().getCreateEditLine(this.model), _.bind(function (field) {
                    if (field.param && field.param.needExist && this.model.isNew()) {
                        return;
                    }
                    this._displayRegion(field);
                }, this));

                _.filter(this.model.getEntity().getCreateEditLine(this.model), _.bind(function (field) {
                    _.each(field.param.events, _.bind(function (event, name) {
                        switch (name) {
                            case 'change':
                                this.listenTo(this.model, 'change', _.bind(event, this, this.model));
                                break;
                        }
                    }, this));
                }, this));
                this.initDatePicker(this.ui.date);

            },
            onDelete: function () {
                this.trigger('item:delete', this.model);
            },
            onChangeCurrentModel: function (fieldName, model) {
                if (model) {
                    model.unset('autocompleteValue', {silent: true});
                    var newModel = this.model.getEntity().getModel(model.toJSON());
                    var collection = this.model.collection;
                    collection.remove(this.model);
                    this.model = newModel;
                    this.model.collection = collection;
                    collection.add(this.model);
                }
            },
            onChangeAutoComplete: function (field, fieldName, model) {
                if (model) {
                    model.unset('autocompleteValue', {silent: true});
                    if (!_.isEqual(_.sortBy(this.model.get(fieldName)), _.sortBy(model.toJSON()))) {
                        this.onChange(fieldName, model.toJSON());
                    }
                } else {
                    this.onChange(fieldName, null);
                }
                if (field && field.param && field.param.onChange) {
                    field.param.onChange(fieldName, model, this.model);
                }
            }
        });
    });
